import request from '@/utils/request';

//详情
export function getDisSetting(data) {
  return request({
    url:'/dis/setting/detail.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateDisSetting(data) {
  return request({
    url :'/dis/setting/updateSetting.php',
    method : 'post',
    data : data
  })
}
